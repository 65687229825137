import React, { useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import PropTypes from "prop-types";
import { ANIMATIONS } from "../../utils/animations";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../../utils/constants";

const AnimatedBlock = ({
  children,
  index = 1,
  duration = 1,
  className = "w-full h-full",
  animatonFx = "default",
  style = {},
}) => {
  const controls = useAnimation();
  const [ref, inView, entry] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView, entry]);

  const { breakpoint } = useBreakpoint(BREAKPOINTS, "desktop");

  return breakpoint === "desktop" ? (
    <motion.div
      ref={ref}
      animate={controls}
      initial='hidden'
      variants={ANIMATIONS[animatonFx](duration, index)}
      className={className}
      style={style}
    >
      {children}
    </motion.div>
  ) : (
    <div ref={ref} className={className} style={style}>
      {children}
    </div>
  );

  return (
    <div ref={ref} className={className} style={style}>
      {children}
    </div>
  );
};

AnimatedBlock.propTypes = {
  children: PropTypes.node.isRequired,
  animatonFx: PropTypes.object,
  index: PropTypes.number,
  duration: PropTypes.number,
  className: PropTypes.string,
};

export default AnimatedBlock;
