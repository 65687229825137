import {
  faAmazon,
  faApple,
  faBandcamp,
  faDeezer,
  faDribbble,
  faDropbox,
  faFacebook,
  faFacebookMessenger,
  faGoogleDrive,
  faInstagram,
  faSnapchatGhost,
  faSoundcloud,
  faSpotify,
  faTelegramPlane,
  faTwitter,
  faXTwitter,
  faWhatsapp,
  faLinkedin,
  faPatreon,
  faTiktok,
  faYoutube,
  faPaypal,
  faBitcoin,
  faReddit,
  faTwitch,
  faSteam,
  faDiscord,
  faWikipediaW,
  faEthereum,
  faThreads,
  faTelegram,
  faLinkedinIn,
  faGoogle,
  faShopify
} from '@fortawesome/free-brands-svg-icons';

import {
  faGlobe,
  faUser,
  faEnvelope,
  faClipboard,
  faWallet,
  faHandHoldingDollar,
  faBoxHeart,
  faDownload,
  faMusic,
  faCompactDisc,
  faShirt,
  faShoppingCart,
  faMusicNote,
  faHouse,
  faHeart,
  faCoin,
  faRecordVinyl,
  faTicket,
  faPhone,
  faQuestion,
  faCircleExclamation,
  faHandHorns,
  faMagnifyingGlass,
  faImage,
  faCartShopping,
  faCalendarDays,
  faGift,
  faFilm,
  faHeadphones,
  faList,
  faGear,
  faBook,
  faPrint,
  faShare,
  faFolder,
  faComments, 
  faThumbTack,
  faCode,
  faTree,
  faSliders,
  faHandshake,
  faCloudArrowUp,
  faUsers,
  faBusinessTime,
  faPuzzlePiece,
  faLightbulb,
  faMicrophone,
  faVoicemail,
  faFilePdf,
  faEarthAmericas,
  faLocationDot,
  faGamepad,
  faCalendarClock,
  faSpeaker
} from '@fortawesome/pro-light-svg-icons';



export const ICON_LINKS = {
  faGoogle,
  faShopify,
  faMagnifyingGlass,
  faImage,
  faCartShopping,
  faCalendarDays,
  faGift,
  faFilm,
  faHeadphones,
  faList,
  faGear,
  faBook,
  faPrint,
  faShare,
  faFolder,
  faComments, 
  faThumbTack,
  faCode,
  faTree,
  faSliders,
  faHandshake,
  faCloudArrowUp,
  faUsers,
  faBusinessTime,
  faPuzzlePiece,
  faLightbulb,
  faMicrophone,
  faVoicemail,
  faFilePdf,
  faEarthAmericas,
  faLocationDot,
  faGamepad,
  faCalendarClock,
  faSpeaker,
  faLinkedinIn : faLinkedin,
  hand_horns: faHandHorns,
  amazon_music: faAmazon,
  apple_music: faApple,
  band_camp: faBandcamp,
  bitcoin: faBitcoin,
  clipboard: faClipboard,
  deezer: faDeezer,
  discord: faDiscord,
  dribble: faDribbble,
  dropbox: faDropbox,
  email: faEnvelope,
  facebook: faFacebook,
  facebookMessenger: faFacebookMessenger,
  google_drive: faGoogleDrive,
  instagram: faInstagram,
  linkedin: faLinkedin,
  other: null,
  patreon: faPatreon,
  paypal: faPaypal,
  person: faUser,
  reddit: faReddit,
  snapchat: faSnapchatGhost,
  sound_cloud: faSoundcloud,
  spotify: faSpotify,
  steam: faSteam,
  telegram: faTelegramPlane,
  tiktok: faTiktok,
  twitch: faTwitch,
  twitter: faXTwitter,
  web: faGlobe,
  whatsapp: faWhatsapp,
  wikipedia: faWikipediaW,
  youtube: faYoutube,
  wallet: faWallet,
  threads: faThreads,
  telegram: faTelegram,
  donation: faHandHoldingDollar,
  donation_2: faBoxHeart,
  download: faDownload,
  music: faMusic,
  compact_disc: faCompactDisc,
  shirt: faShirt,
  cart: faShoppingCart,
  music_note: faMusicNote,
  house: faHouse,
  heart: faHeart,
  coin: faCoin,
  record_vinyal: faRecordVinyl,
  ticket: faTicket,
  phone: faPhone,
  question_mark: faQuestion,
  exclamation: faCircleExclamation,
};

export const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 };

export const SHADOW = {
  BIG: 'BIG',
  LARGE: 'LARGE',
  SMALL: 'SMALL',
  NONE: false,
};

export const SEO_DEFAULT = `
<link rel="canonical" href="https://danielweb.com" />
<meta property="og:locale" content="en_US" />
<meta property="og:title" content="New Home | Daniel Morales" />
<meta property="og:url" content="https://danielweb.com/" />
<meta property="og:site_name" content="Daniel Morales" />
<meta property="article:publisher" content="https://www.facebook.com/DunielAV" />
<meta property="article:modified_time" content="${new Date().toDateString()}" />
<meta property="og:image" content="https://danielweb.com/wp-content/uploads/2022/10/dunielaurora-morales.jpg" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:site" content="@DunielMM" />
<script type="application/ld+json" class="yoast-schema-graph">{"@context":"https://schema.org","@graph":[{"@type":"WebPage","@id":"https://danielweb.com/new-home/","url":"https://danielweb.com/new-home/","name":"New Home | Daniel Morales","isPartOf":{"@id":"https://danielweb.com/#website"},"datePublished":"2022-08-04T23:10:30+00:00","dateModified":"2023-06-11T21:01:02+00:00","breadcrumb":{"@id":"https://danielweb.com/new-home/#breadcrumb"},"inLanguage":"en-US","potentialAction":[{"@type":"ReadAction","target":["https://danielweb.com/new-home/"]}]},{"@type":"BreadcrumbList","@id":"https://danielweb.com/new-home/#breadcrumb","itemListElement":[{"@type":"ListItem","position":1,"name":"Home","item":"https://danielweb.com/"},{"@type":"ListItem","position":2,"name":"New Home"}]},{"@type":"WebSite","@id":"https://danielweb.com/#website","url":"https://danielweb.com/","name":"Daniel Morales","description":"Portfolio &amp; Work of Daniel Morales | Music • Audio Visuals • Technology","potentialAction":[{"@type":"SearchAction","target":{"@type":"EntryPoint","urlTemplate":"https://danielweb.com/?s={search_term_string}"},"query-input":"required name=search_term_string"}],"inLanguage":"en-US"}]}</script>
`;
