import React, { useEffect } from 'react';
import { getCurrentYear } from '../../lib/util';
// import { getFooterData } from '../../queries/footer.preval';
import { getFooterDetails } from '../../queries/pages';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICON_LINKS } from '../../utils/constants';
import { v4 as uuidv4 } from 'uuid';
import Link from 'next/link';

const Footer = () => {
  const [footerData, setFooterData] = React.useState({});

  useEffect(() => {
    const getFooterData = async () => {
      const footerData = await getFooterDetails();
      setFooterData(footerData);
    };
    getFooterData();
  }, []);

  return (
    <div
      className='w-full bg-neutral-900 text-white p-4 md:py-8 md:px-8 md:max-h-[554px] md:min-h-[554px] md:h-[554px] text-center md:text-left'
      style={{ backgroundImage: 'url(/images/pattern_50.png)' }}
    >
      <div className='container grid grid-cols-1 gap-12 md:grid-cols-12 md:gap-20'>
        <div className='flex flex-col col-span-1 md:col-span-4'>
          <Link scroll={false} href='/' legacyBehavior>
            <img
              src='/images/logo-footer.png'
              alt=''
              className='mt-12 md:mt-[3.4em] cursor-pointer'
            />
          </Link>
          <p className='hidden md:block md:mt-auto'>
            <FontAwesomeIcon icon={ICON_LINKS.email} className='block-inline' />{' '}
            info@danielweb.com
          </p>
          <p className='hidden md:block md:mb-12'>
            <strong>[ {getCurrentYear()} ]</strong> - All Rights Reserved -
            Daniel Morales
          </p>
        </div>
        <div className='grid grid-cols-1 col-span-1 col-start-1 gap-5 md:col-start-6 md:col-span-7 md:grid-cols-12 md:gap-10'>
          <div className='grid col-span-1 grid-rows-1 gap-10 mb-6 md:grid-rows-6 md:col-span-3 md:mb-0'>
            <div className='self-end row-span-1 md:row-span-2'>
              <h3 className='block text-4xl font-bold uppercase font-now'>
                Recent Work
              </h3>
            </div>
            <div className='row-span-1 md:row-span-4'>
              <ul className='flex flex-col w-full'>
                {footerData.projects?.map((project, i) => (
                  <li
                    className='w-full mb-6 transition-all duration-100 cursor-pointer hover:scale-110 hover:font-bold'
                    key={uuidv4()}
                  >
                    <Link
                      scroll={false}
                      href={project.linkProjects.url}
                      legacyBehavior
                    >
                      <span className='text-xl'>
                        {project.linkProjects.title}
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className='grid col-span-1 col-start-1 grid-rows-1 gap-5 mb-6 md:grid-rows-6 md:col-start-4 md:col-span-5 md:gap-10 md:mb-0 md:pl-20'>
            <div className='self-end row-span-1 mx-auto md:row-span-2'>
              <h3 className='block text-4xl font-bold uppercase font-now'>
                current availability
              </h3>
            </div>
            <div className='row-span-1 mx-auto md:row-span-4'>
              <p>{footerData?.availabilityText}</p>
              <Link scroll={false} href={'/contact-me'} legacyBehavior>
                <span className='flex items-center justify-center w-3/4 h-16 mt-8 mb-6 ml-auto mr-auto text-3xl font-bold text-center uppercase duration-100 border-4 cursor-pointer font-now md:mt-8 md:mb-10 hover:bg-white md:ml-0 md:mr-auto hover:text-black ransition-all'>
                  contact
                </span>
              </Link>
            </div>
          </div>
          <div className='grid col-span-1 col-start-1 grid-rows-1 gap-5 mb-6 md:grid-rows-6 md:col-start-10 md:col-span-3 md:gap-10 md:mb-0'>
            <div className='self-end row-span-1 md:row-span-2'>
              <h3 className='block text-4xl font-bold uppercase font-now'>
                Find me on
              </h3>
            </div>
            <div className='row-span-1 md:row-span-4'>
              <ul className='flex flex-col w-full'>
                {footerData?.links?.map(({ icon, link }, i) => (
                  <li
                    className='items-center w-full mb-6 text-xl transition-all duration-100 group hover:scale-110 hover:font-bold'
                    key={uuidv4()}
                  >
                    {icon && (
                      <FontAwesomeIcon
                        icon={ICON_LINKS[icon]}
                        className='mr-2'
                      />
                    )}
                    <a href={link.url} target={link.target}>
                      {link.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className='block md:hidden md:mt-auto'>
              <p>
                <FontAwesomeIcon
                  icon={ICON_LINKS.email}
                  className='block-inline'
                />{' '}
                info@danielweb.com
              </p>
              <p>
                <strong>[ {getCurrentYear()} ]</strong> - All Rights Reserved -
                Daniel Morales
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
