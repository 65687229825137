const bottomAppear = (duration, index) => ({
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration, ease: "easeInOut", delay: index * 0.1 },
  },
  hidden: { opacity: 0.45, y: 45 },
});

const pop = (duration, index) => ({
  visible: {
    opacity: 1,
    transition: { duration, ease: "easeInOut", delay: index * 0.1 },
  },
  hidden: { opacity: 1 },
});

export const ANIMATIONS_OPTIONS = {
  default: "default",
  pop: "pop",
};

export const ANIMATIONS = {
  [ANIMATIONS_OPTIONS.default]: (duration = 1, index = 1) =>
    bottomAppear(duration, index),
  [ANIMATIONS_OPTIONS.pop]: (duration = 1, index = 1) => pop(duration, index),
};
