import React from "react";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./ScrollDownAnimation.module.css";
import { faHandBackPointUp } from "@fortawesome/pro-solid-svg-icons";

const ScrollDownAnimation = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "desktop");
  return breakpoint === "desktop" ? (
    <div className={styles.Mouse}>
      <div className={styles.Wheel}></div>
    </div>
  ) : (
    <FontAwesomeIcon icon={faHandBackPointUp} className={styles.Hand} />
  );
};

export default ScrollDownAnimation;
