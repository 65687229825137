import { gql } from '@apollo/client';

export const ABOUT_ME_HOME = gql`
  query ($id: ID!) {
    page(id: $id, idType: URI) {
      content
      title
      seo {
        fullHead
      }
      CFHOMEAboutMe {
        buttonlink {
          title
          url
        }
        homestext
        imagehomemobile {
          altText
          sourceUrl
        }
        imagehomedesktop {
          altText
          sourceUrl
        }
      }
    }
  }
`;

export const ABOUT_ME_HOME_EXCERPT = gql`
  query ($id: ID!) {
    page(id: $id, idType: URI) {
      content
      title
      seo {
        fullHead
      }
      CFResumeLink {
        backgroundResume
        resumeBtn
        resumeLink
      }
      aboutMeFields {
        buttonlink {
          title
          url
        }
        homestext
        imagehomedesktop {
          altText
          sourceUrl
        }
        imagehomemobile {
          altText
          sourceUrl
        }
        sections {
          bgcolor
          textcolor
          content
          isImageLeft
          title
          customClass
          image {
            sourceUrl
            srcSet
            uri
            altText
          }
        }
      }
    }
  }
`;

export const HOMEPAGE = gql`
  query getHome($id: ID!) {
    page(id: $id, idType: DATABASE_ID) {
      CFProjectMediaAndPress {
        aspectRatioMediaPress
        visibleSlidesMediaPress
        mediaAndPress {
          title
          sourceUrl
          srcSet
          altText
          caption(format: RENDERED)
          description
          id
        }
      }
      CFHomeCards {
        businessVenture {
          image {
            altText
            sourceUrl
          }
          link {
            url
            target
          }
          title
        }
        projects {
          image {
            altText
            sourceUrl
          }
          link {
            url
            target
          }
          title
        }
      }
      CFProjectQuotes {
        backgroundQuotes
        quotes {
          author
          link
          quote
          quoteLink
        }
      }
      seo {
        fullHead
      }
    }
  }
`;

export const PAGE_MENU = gql`
  query getMenu($id: ID!) {
    menu(id: $id, idType: NAME) {
      menuItems {
        nodes {
          path
          label
        }
      }
    }
  }
`;

export const FOOTER_DETAILS = gql`
  query FooterDetails($id: ID!) {
    page(id: $id, idType: DATABASE_ID) {
      CFFooterDetails {
        availabilityText
        recentProjects {
          linkProjects {
            title
            url
          }
        }
        links {
          icon
          link {
            target
            title
            url
          }
        }
      }
    }
    projects(last: 4) {
      nodes {
        uri
        title
      }
    }
  }
`;

export const CONTACT_PAGE = gql`
  query getContactPage($id: ID!) {
    page(id: $id, idType: URI) {
      content
      title
      seo {
        fullHead
      }
    }
  }
`;

export const GET_IMAGES_CONTACT = gql`
  query getContactImages($id: ID!) {
    page(id: $id, idType: URI) {
      PageContactme {
        galleryThankYouImages {
          id
          sourceUrl
          contentTypeName
        }
      }
    }
  }
`;

export const GET_URLS = gql`
  query GetUrls {
    projects(first: 1000) {
      nodes {
        uri
      }
    }
    businessVentures(first: 10) {
      nodes {
        uri
      }
    }
  }
`;
