import classNames from 'classnames';
import React from 'react';
import AnimatedBlock from '../AnimatedBlock';

export const ClonnedComponent = React.forwardRef((props, ref) => {
  const {
    children,
    component = 'section',
    className = '',
    sectionClassName = '',
    isAnimated,
    isFullWidth = false,
    customPadding = '',
    ...rest
  } = props;

  const customBg = className.match(/bg-\'?\w+([-']\w+)*\'?/g)?.join(' ');

  const sectionClasses = classNames(className);

  const style = rest?.style;

  return React.createElement(
    component,
    {
      ...rest,
      className: classNames(customBg, {
        'md:px-0': !isFullWidth,
        'w-full': isFullWidth,
      }),
      ref,
    },
    isAnimated ? (
      <AnimatedBlock className={sectionClasses} style={style}>
        {children}
      </AnimatedBlock>
    ) : sectionClasses || style ? (
      <div className={sectionClasses} style={style}>
        {children}
      </div>
    ) : (
      children
    )
  );
});

ClonnedComponent.displayName = 'ClonnedComponent';

const Section = React.forwardRef((props, ref) => {
  const { displaySection = true } = props;
  return displaySection ? <ClonnedComponent {...props} ref={ref} /> : null;
});

Section.displayName = 'Section';
export default Section;
